import { memo, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ReactNotifications } from 'react-notifications-component';

import 'react-notifications-component/dist/theme.css';

import Clock from '../../components/Clock';
import Footer from '../../components/footer';
import { createGlobalStyle } from 'styled-components';
import Web3 from "web3";
import * as selectors from '../../../../store/selectors';
import * as actions from '../../../../store/actions/thunks';
import * as setActions from '../../../../store/actions';
import api from '../../../../core/api';
import { useParams, useHistory } from 'react-router';
import Header from '../../menu/header';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { Typography,Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';

import MarketplaceAbi from "../../../../artifacts/contracts/Marketplace.sol/Marketplace.json";
import EIVAbi from "../../../../artifacts/contracts/EIVToken.sol/EIVToken.json";
import SNAPSHOTAbi from "../../../../artifacts/contracts/MarketplaceSnapshot.sol/MarketplaceSnapshot.json";

import ModalNoMetamask from "../../../../components/Servererror/modalNoMetamask";
import Nft3dCard from '../../../../components/Cards/nft3DCard';
import useStyles from '../../../../styles/pages/Explore/AssetDetails';
import NftCard from '../../components/NftCard';
import AssetCardFooter from '../../components/AssetCardFooter';
import { login, register } from '../../../../utils/functions/authenticate';
import { setAssetLike,
  setAssetDislike,
  setAssetWishlist,
  setAssetDiswishlist,
} from '../../../../services/AssetService';
import { createNFT, admintNFT } from '../../../../services/NftService';
import * as CouponService from "../../../../services/CouponService";
import * as DataService from "../../../../services/DataService";
import * as TagService from "../../../../services/TagService";
import * as UtilityService from "../../../../services/UtilityService";
import * as CategoryService from "../../../../services/CategoryService";
import * as AuthService from "../../../../services/AuthService";
import Notification from "../../../../utils/functions/notification";
import Allowance from "../../../../utils/functions/allowance";
import enableWeb3 from "../../../../utils/functions/enableWeb3";
import { BigNumber } from '@ethersproject/bignumber';
import { useWriteContract, useTransactionConfirmations, useAccount } from 'wagmi';
import { useAppKit } from '@reown/appkit/react';

const GlobalStyles = createGlobalStyle `
  header#myHeader.navbar.white {
    background: #fff;
    border-bottom: solid 1px #dddddd;
  }
  header#myHeader.navbar .search #quick_search{
    color: #20005e;
    background: rgba(255, 255, 255, .1);
    }
    header#myHeader.navbar.white .btn, .navbar.white a{
        color: #20005e;
    }
    header#myHeader.navbar.sticky.white .btn, .navbar.sticky.white a{
        color: #20005e;
    }
    header#myHeader .dropdown-toggle::after{
        color: #20005e;
    }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #8364E2 !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

const AssetDetails = () => {
  const { nftId } = useParams();
  const [marketplaceContract, setMarketplaceContract] = useState();
  const [snapshotContract, setSnapshotContract] = useState();
  // eslint-disable-next-line
  const [eivContract, setEivContract] = useState();
  // eslint-disable-next-line

  const [isActive, setActive] = useState(false);
  // const [isListed, setIsListed] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Please wait...');
  const [vaultAmount, setVaultAmount] = useState(0);
  const [vaultPercent, setVaultPercent] = useState(0);
  const [isVaultChecked, setVaultChecked] = useState(false);
  const [isVault, setIsVault] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(false);
  const [exchangeRateScale, setExchangeRateScale] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [isCouponError, setCouponError] = useState(false);
  const [isCouponSet, setCouponSet] = useState(false);
  const [isCouponOverdue, setCouponOverdue] = useState(false);
  const [isFetchingCoupon, setFetchingCoupon] = useState(false);
  const [limitDate, setLimitDate] = useState();
  const [categories, setCategories] = useState([]);
  const { address } = useAccount();   
  const { open } = useAppKit();
  const signedUser = useSelector(selectors.user);

  const marketplaceAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const snapshotAddress = process.env.REACT_APP_SNAPSHOT_CONTRACT;
  const eivAddress = process.env.REACT_APP_EIV_ADDRESS;
  const companyWalletAddress = process.env.REACT_APP_COMPANY_WALLET_ADDRESS;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_AVALANCHE_URL));
  const { writeContract, isSuccess, isError, data } = useWriteContract();
  
  // eslint-disable-next-line
  const [currentSM, setCurrentSM] = useState();
  const [price, setPrice] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [render, setRender] = useState(false);
  const [actionType, setActionType] = useState();
  const [page, setPage] = useState(0);
  const [continents, setContinents] = useState([]);

  const [openCheckout, setOpenCheckout] = useState(false);
  const [countLike, setCountLike] = useState();
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);

  const [tags, setTags] = useState([]);
  const [utilities, setUtilities] = useState([]);

  const [hash, setHash] = useState();
  const { status } = useTransactionConfirmations({hash: hash});

  const isVoted = useRef(false);
  const isWishlisted = useRef(false);

  const history = useHistory();
  const classes=useStyles();

  useEffect(() => {
    if (status === 'success') {
      if(currentSM === 'approve') {
        createNFT(nft?.id, {"isVault": isVault, "coupon": coupon, "isAdmint": false}).then((res) => {
          dispatch(actions.fetchAssetDetail(nftId));
          dispatch(actions.fetchNotifications());
          setOpenCheckout(false);
          setActive(false);
          Notification(`You purchased an asset.`, true);
        })
        .catch((error) => {
          let msg = (typeof error === 'object') ? error.message : error;
          setActive(false);
          Notification(msg, false);
        })
        
      }
    }
    else if (status === 'error') {
      if(currentSM === 'approve') {
        setActive(false);
        setOpenCheckout(false);
        Notification('Not sufficient allowance.', false);
      }
    }
  }, [status]);

  useEffect(() => {
    if (isSuccess) {
      setHash(data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      if(currentSM === 'approve') {
        setActive(false);
        setOpenCheckout(false);
        Notification('Not sufficient allowance.', false);
      }
    }
  }, [isError]);

  useEffect(() => {
    DataService.fetchContinents().then((res => {
      setContinents(res);
    }));

    TagService.fetchTags().then((res) => {
      setTags(res);
    });

    UtilityService.fetchUtilities().then((res) => {
      setUtilities(res);
    });

    CategoryService.fetchCategory({exclude: 1}).then((res => {
      setCategories(res);
    })); 
  }, []);

  useEffect(() => {
    setRender(!render);
  }, [isVoted.current, isWishlisted.current]);

  useEffect(() => {
    init().then((res) => {
      dispatch(actions.fetchAssetDetail(nftId));
    });
    // eslint-disable-next-line
  }, [dispatch, nftId]);

  let nft = useSelector(state=>state.NFT.assetDetail.data);

  const fetchCoupon = (value, amount) => {
    setFetchingCoupon(true);
    if(value === '' || value === null) {
      setCouponError(false);
      setCoupon('');
      setCouponSet(false);
      setPrice(amount);
      setFetchingCoupon(false);
      setCouponOverdue(false);
    }
    else {
      CouponService.fetchCoupon(value).then(res => {
        if (res) {
          if (res === true) {
            setCouponError(true);
            setCouponSet(false);
            setCoupon('');
            setPrice(amount);
            setFetchingCoupon(false);
            setCouponOverdue(true);
          }
          else {
            setCouponError(false);
            setCouponSet(true);
            setCoupon(res.id);
            setPrice(parseInt(amount * (1 - res.discount / 100)));
            setFetchingCoupon(false);
            setCouponOverdue(false);
          }
        } else {
          setCouponError(true);
          setCouponSet(false);
          setCoupon('');
          setPrice(amount);
          setFetchingCoupon(false);
          setCouponOverdue(false);
        }
      });
    }
  }

  const [debounceFetchCoupon] = useState(() => debounce(fetchCoupon, 1000));

  useEffect(() => {
    setCountLike(nft?.likes); 
    if (nft && marketplaceContract) {

      marketplaceContract.methods.EXCHANGE_RATE_SCALE().call().then(result => {
        setExchangeRateScale(parseFloat(result));
      });

      marketplaceContract.methods.exchangeRate().call().then(result => {
        setExchangeRate(parseFloat(result));
      });

      if(nft.Nfts && nft.Likes) {
        let totalLikes = nft.Likes.length;
        for(let i=0; i<nft.Nfts.length; i++) {
            totalLikes += nft.Nfts[i].NftLikes.length
        }
        setCountLike(totalLikes);
      }

      if (nft) {
        setPrice(parseFloat(nft?.price));
        if(nft?.limitDate) {
          const dateParts = nft?.limitDate.split('-');
          const year = parseInt(dateParts[0]);
          const month = parseInt(dateParts[1]) - 1; // Month is 0-based in JavaScript
          const day = parseInt(dateParts[2]);
          const targetDate = new Date(year, month, day);
          setLimitDate(targetDate);
        }

        if(nft.Likes) {
          for(let i=0; i<nft.Likes.length; i++) {
              if(signedUser.metamask === nft.Likes[i].User.metamask.toLowerCase()) {
                isVoted.current = true;
              }
          }
        }

        if(nft.Wishlists) {
            for(let i=0; i<nft.Wishlists.length; i++) {
              if(signedUser.metamask === nft.Wishlists[i].User.metamask.toLowerCase()) {
                isWishlisted.current = true;
              }
            }
        }
      }
    }
    // eslint-disable-next-line
  }, [nft, marketplaceContract]);

  useEffect(() => {
    setCoupon('');
    setCouponError(false);
    setPrice(parseFloat(nft?.price));
  }, [openCheckout]);

  const init = async() => {
    // if(window.ethereum) {
      const marketplaceContract = new web3.eth.Contract(MarketplaceAbi.abi, marketplaceAddress);
      const eivContract = new web3.eth.Contract(EIVAbi.abi, eivAddress);
      const snapshotContract = new web3.eth.Contract(SNAPSHOTAbi.abi, snapshotAddress);

      setMarketplaceContract(marketplaceContract);
      setSnapshotContract(snapshotContract);
      setEivContract(eivContract);
    // }
  }

  const auth = async () => {
    open();
    // if (window.ethereum) {
      // login(dispatch, setActions.setLoading, setActions.setUser, history, setServerError, setShowModal);
    // }
  };

  const handleLike = async (userData) => {
    setActionType();
    if(nft.Likes) {
        for(let i=0; i<nft.Likes.length; i++) {
            if(userData.metamask === nft.Likes[i].User.metamask.toLowerCase()) {
              isVoted.current = true;
            }
        }
    }

    if(!isVoted.current) {
      dispatch(setActions.setLoading(true));
      const res = await setAssetLike(nft?.id);
      try {
        let totalLikes = res.likes;
        for(let i=0; i<nft.Nfts.length; i++) {
            totalLikes += nft.Nfts[i].NftLikes.length
        }
        setCountLike(totalLikes);
        setShowAlertModal(true);
        setAlertMessage('Your like has been added.');
        isVoted.current = true;
        dispatch(actions.fetchNotifications());
      } catch(e) {
          alert(e);
      }
      dispatch(setActions.setLoading(false));
    }
    else {
      dispatch(setActions.setLoading(true));
      const res = await setAssetDislike(nft.id);
      try {
        let totalLikes = res.likes;
        for(let i=0; i<nft.Nfts.length; i++) {
            totalLikes += nft.Nfts[i].NftLikes.length
        }
        setCountLike(totalLikes);
        setShowAlertModal(true);
        setAlertMessage('Your like has been removed.');
        isVoted.current = false;
        dispatch(actions.fetchNotifications());
      } catch(e) {
        alert(res.response);
      }
      dispatch(setActions.setLoading(false));
    }
  }

  const onClickLike = () => {
    if(signedUser.id) {
        handleLike(signedUser);
    }
    else {
        setShowModal(true);
        setActionType('like');
    }
  }

  const onClickBasket = () => {
    if(signedUser.id) {
        handleWishlist(signedUser);
    }
    else {
        setShowModal(true);
        setActionType('wishlist');
    }
  }

  const handleWishlist = async (userData) => {
    setActionType();

    if(nft.Wishlists) {
        for(let i=0; i<nft.Wishlists.length; i++) {
            if(userData.metamask === nft.Wishlists[i].User.metamask.toLowerCase()) {
              isWishlisted.current = true;
            }
        }
    }

    if(!isWishlisted.current) {
      dispatch(setActions.setLoading(true));
      try {
        const res = await setAssetWishlist(nft?.id);
        dispatch(actions.fetchNotifications());
        setShowAlertModal(true);
        isWishlisted.current = true;
        setAlertMessage('This NFT has been added to your wishlist.');
      } catch(e) {
        alert(e);
      }
      dispatch(setActions.setLoading(false));
    }
    else {
      dispatch(setActions.setLoading(true));
      try {
        const res = await setAssetDiswishlist(nft.id);
        dispatch(actions.fetchNotifications());
        setShowAlertModal(true);
        isWishlisted.current = false;
        setAlertMessage('This Asset has been removed from your wishlist.');
      }
      catch(e) {
        alert(e);
      }
      dispatch(setActions.setLoading(false));
    }
  }

  const handleBuyNow = () => {
    if (signedUser.id) {
      dispatch(setActions.setLoading(true));
      AuthService.sessionCheck().then((res) => {
        dispatch(setActions.setLoading(false));
        snapshotContract.methods.getInfo(signedUser.metamask).call().then(result => {
          setOpenCheckout(true);
          setCoupon("");
          setVaultChecked(false);
          setVaultAmount(parseFloat(Web3.utils.fromWei(result[0], 'mwei')));
  
          snapshotContract.methods.EQUITY_INITIAL_SUPPLY().call().then(initialSupply => {
            const nftPrice = BigNumber.from(nft?.price);
            const balance = BigNumber.from(result[0]);
            const exchangeRateScaled = nftPrice.mul(exchangeRateScale).div(exchangeRate);
            const subtracted = balance.sub(exchangeRateScaled);
            const divided = parseFloat(Web3.utils.fromWei(subtracted.toString(), 'mwei')) * 100 / parseFloat(Web3.utils.fromWei(initialSupply, 'mwei'));
            setVaultPercent(divided);
          })
        });
      })
      .catch(err => {
        dispatch(setActions.setLoading(false));
        if(err.response?.status == '401') {
          dispatch(actions.setIsTimeout(true));
        }
      })
    } else {
      setShowModal(true);
    }
  };

  const buyNFT = async (vault) => {
    setActive(true);
    // const enabled = await enableWeb3();
    // if (!enabled) {
    //   Notification('Waiting for metamask sign in.', false);
    //   return
    // }
    setIsVault(vault);
    setLoadingMessage("Please wait while you purchase.");
    const account = signedUser.metamask;
    try {
      // let amount = Web3.utils.toWei('0.001', 'mwei');
      let amount = Web3.utils.toWei('0', 'mwei');
      if (!vault) {
        amount = Web3.utils.toBN(amount).add(Web3.utils.toBN(price));
      }
      if(!vault) {
        const allowed = await eivContract.methods.allowance(account, marketplaceAddress).call();

        setCurrentSM('approve');
        if(allowed < parseInt(price)) {
          writeContract({ 
            abi: EIVAbi.abi,
            address: eivAddress,
            functionName: 'approve',
            args: [
              marketplaceAddress,
              parseInt(price),
            ],
          });
        }
        else {
          await createNFT(nft?.id, {"isVault": vault, "coupon": coupon, "isAdmint": false});
          dispatch(actions.fetchAssetDetail(nftId));
          dispatch(actions.fetchNotifications());
          setOpenCheckout(false);
          setActive(false);
          Notification(`You purchased an asset.`, true);
        }
        // await Allowance(eivContract, account, marketplaceAddress, amount);
      }
      else {
        await createNFT(nft?.id, {"isVault": vault, "coupon": coupon, "isAdmint": false});
        dispatch(actions.fetchAssetDetail(nftId));
        dispatch(actions.fetchNotifications());
        setOpenCheckout(false);
        setActive(false);
        Notification(`You purchased an asset.`, true);
      }
    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
    }
  }

  const admint = async () => {
    setActive(true);
    setLoadingMessage("Please wait while you admint.");
    try {
      await admintNFT(nft?.id, {"isAdmint": true});

      dispatch(actions.fetchAssetDetail(nftId));
      dispatch(actions.fetchNotifications());
      setOpenCheckout(false);
      setActive(false);
      Notification(`You adminted an asset.`, true);

    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
    }
  }

  const handleAuth = async () => {
    const userData = await login(dispatch, setActions.setLoading, setActions.setUser, history, setServerError);
    setShowModal(false);
    if(userData && actionType === 'like') {
        handleLike(userData);
    }
    if(userData && actionType === 'wishlist') {
        handleWishlist(userData);
    }
  }

  const handleSearchCoupon = (value) => {
    debounceFetchCoupon(value, parseInt(nft?.price));
  }

  const loadMore = () => {
    setPage(page + 1);
  }

  const displayTags = (tagsJson) => {
    const tagIds = JSON.parse(tagsJson);
    let tagNames = []
    for(let i=0; i<tagIds.length; i++) {
      if(tags.find(tag => tag.id === tagIds[i]))
        tagNames.push(tags.find(tag => tag.id === tagIds[i]).name);
    }

    return tagNames.join(', ');
  }

  const displayUtilities = (utilitiesJson) => {
    const utilityIds = JSON.parse(utilitiesJson);
    let utilityNames = []
    for(let i=0; i<utilityIds.length; i++) {
      if(utilities.find(utility => utility.id === utilityIds[i]))
        utilityNames.push(utilities.find(utility => utility.id === utilityIds[i]).name);
    }

    return utilityNames.join(', ');
  }

  const displayCategories = (category) => {
    let categoryNames = [];

    if(category) {
      let temp = category;
      categoryNames.push(category.name);
      while(true) {
        temp = categories.find(c => c.id === temp.parent);
        if(temp){
          categoryNames.push(temp.name);
          if(temp.parent === 0) break;
        }
        else {
          break;
        }
      }
    }

    return categoryNames.reverse().join(' / ');
  }

  return (
    <div>
      <LoadingOverlay
        style={{zIndex: 3000}}
        active={isActive}
        spinner
        text={loadingMessage}>
      <GlobalStyles />
      {/* <StyledHeader theme={theme} /> */}
      <Header className='HOME' />
      <ReactNotifications />
      <section className='container'>
        <div className='row pt-md-4 mb-20'>
          <div className="col-md-12 text-center">
            <h3>3D Asset Details</h3>
          </div>
          <div className='col-md-7 text-center'>
            <div style={{border: '1px solid #DDD', borderRadius: 15}}>
              { nft && nft.gltf && nft.id === parseInt(nftId) &&
                  <Nft3dCard src={`${api.server3dnTech}/${nft.directory}/${nft.gltf}`} directory={`${api.server3dnTech}/${nft.directory}/`} height={450} border={true} ratio={0.7}/>
              }
            </div>
            { nft && !nft.gltf && <img src={api.ipfsURL + nft.image} className="img-fluid img-rounded mb-sm-30" alt=""/>}
            {nft && nft.gltf && <p style={{fontWeight: 700}}>To view this 3D Assets, click on the image and move your mouse. You can also mouse scroll to zoom-in and zoom-out in the preview</p>}
            {nft && nft.gltf && <p style={{fontSize: 14}}>Disclaimer: The 3D Asset rendering is subject to differences with the real version available inside the Metaverse. Please check preview Video to have an exact render of the asset. Powered by <a href="https://ultimvr.io" style={{color: 'blue'}} target="_blank"><u>UltimVR.io</u></a></p>}
          </div>
          <div className='col-md-5'>
            <div className='item_info'>
              {nft && nft.item_type === 'on_auction' && (
                <>
                  {t("AuctionEnd")}
                  <div className='de_countdown'>
                    <Clock deadline={nft.deadline} />
                  </div>
                </>
              )}
              <h2>{nft && nft.title}</h2>
              <div className='item_info_counts nft__item_info'>
                {/* <div className='item_info_type'>
                  <i className='fa fa-image'></i>
                  {nft && nft.category}
                </div>
                <div className='item_info_views'>
                  <i className='fa fa-eye'></i>
                  {nft && nft.views}
                </div> */}
                <div className={isVoted.current ? "details__item_liked" : "details__item_like"} onClick={() => onClickLike()}>
                  <i className="fa fa-heart"></i><span>{countLike}</span>
                </div>
                <div className={isWishlisted.current ? "details__item_liked" : "details__item_like"} onClick={() => onClickBasket()}>
                  <i className="fa fa-shopping-basket"></i>
                </div> 
              </div>
              <p>Price in USDC : {nft && nft.price && Web3.utils.fromWei(nft.price, 'mwei')} USDC</p>
              <p>{ `NFT` } {nft?.numberOfItems === 0 ? (nft?.limitDate ? (new Date() < limitDate ? `Mints: Until ${limitDate?.getMonth() + 1}/${limitDate?.getDate()}/${limitDate?.getFullYear()}` : `Minted: ${nft?.Nfts.length}`) : 'Mints: Unlimited' ) : `Remaining: ${nft?.numberOfItems - nft?.minted} / ${nft?.numberOfItems}` }</p>
              {nft?.variable && nft?.variable?.find(item => item.variable === 'Continent') && <p><strong>Continent: {continents.find(continent => continent.id === nft?.variable?.find(item => item.variable === 'Continent')?.value)?.name }</strong></p>}
              {nft?.variable && nft?.variable?.find(item => item.variable === 'Level') && <p><strong>Level: {nft?.variable?.find(item => item.variable === 'Level')?.value}</strong></p>}
              {nft?.tag && <p><strong>Tags:</strong> {displayTags(nft?.tag)}</p>}
              {nft?.Category && <p><strong>Category:</strong> {displayCategories(nft?.Category)}</p>}
              {nft?.utility && <p><strong>Utilities: </strong>{displayUtilities(nft?.utility)}</p>}
              <div className='d-flex flex-row'>
                {nft && nft.User && <div className='mr40'>
                  <h6>{t("Owner")}</h6>
                  <div className='item_author'>
                    <div className='author_list_pp'>
                      <span>
                        { nft && nft.User && nft.User.picture ?
                          <img
                            className='lazy'
                            src={
                              nft &&
                              nft.User &&
                              `${api.baseUrl}/${nft.User.picture}`
                            }
                            alt=''
                            onClick={() => history.push({pathname: `/author/${nft.User.id}`})}
                          />
                          // <i className='fa fa-check'></i>
                          :
                          <img className="lazy" src="/img/author/author-11.png" alt="" onClick={() => history.push({pathname: `/author/${nft.User.id}`})}/>
                        }
                      </span>
                    </div>
                    <div className='author_list_info'>
                      <span>{nft && nft.User && nft.User.username}</span>
                    </div>
                  </div>
                </div>}
              </div>
              <div className='d-flex flex-row mt-5'>
                {/* {nft && user && nft?.User?.metamask !== user?.get('ethAddress') && !nft?.isForBid && ((nft?.listed && nft?.minted) || (!nft?.listed && !nft?.minted)) && <button className='btn-main lead mb-5 mr15' onClick={handleBuyNow}>{t("BuyNow")}</button>} */}
                {nft && (nft?.numberOfItems > nft?.minted || (nft?.numberOfItems === 0 && (!nft?.limitDate || new Date() < limitDate))) && <button className='btn-main lead mb-5 mr15' onClick={handleBuyNow}>{t("BuyNow")}</button>}
                {((nft?.numberOfItems === 0 && nft?.limitDate && new Date() > limitDate) || (nft?.numberOfItems !== 0 && nft?.numberOfItems === nft?.minted)) && <div>
                  <h5 style={{color: 'red'}}>Sold Out</h5>
                  <p>(There is {nft?.Nfts?.length} resellers to check below)</p>
                </div>}
                {signedUser.metamask == companyWalletAddress.toLowerCase() && (nft?.numberOfItems > nft?.minted || (nft?.numberOfItems === 0 && (!nft?.limitDate || new Date() < limitDate))) && <button className='btn-main lead mb-5 mr15' onClick={admint}>Admint</button>}
              </div>
              <div className='spacer-40'></div>
            </div>
          </div>
          <div className='col-md-12'>
            {nft && <AssetCardFooter description={nft?.description} variable={nft?.variable} continents={continents} preview={api.server3dnTech + '/' + nft?.directory + '/' + nft?.directory + '.png'} video={nft?.video} media={nft?.media} mediaFile={nft?.mediaFile} histories={nft?.histories} isAsset={true} seller={nft?.User} directory={nft?.directory} previewImages={nft?.previewImages} />}
          </div>
        </div>
        {nft?.Nfts?.length > 0 && <div className='row'>
          <div className="col-md-12 text-center mt-20">
            <h3>NFT Sellers</h3>
          </div>
          {signedUser && nft && nft.Nfts && nft.Nfts.slice(0, (page+1)*6).map( (item, index) => (
            <NftCard priceRate={0} nft={item} key={index} account={signedUser.id} height={250} asset={nft}/>
          ))}
          { nft && nft.Nfts.length > (page+1) * 6 &&
            <div className='col-lg-12'>
                <div className="spacer-single"></div>
                <span onClick={loadMore} className="btn-main lead m-auto">{t("LoadMore")}</span>
            </div>
          }
        </div>}
      </section>

      <Footer />
      </LoadingOverlay>
      {openCheckout && (
        <div className='checkout'>
          <div className='maincheckout'>
            <button
              className='btn-close'
              onClick={() => setOpenCheckout(false)}
            >
              x
            </button>
            <div className='heading'>
              <h3>{t("Checkout")}</h3>
            </div>
            {Web3.utils.fromWei(nft?.price, 'mwei') * exchangeRateScale / exchangeRate < vaultAmount && isVaultChecked === false && nft.User?.metamask === companyWalletAddress.toLowerCase() ?
            <>
              <p>
                {t("ToPurchase")}{' '}
                <span className='bold'>{nft && nft.title}{' '}</span>
                <span>{t("From")}</span>
                <span className='bold'> {nft && nft.User && nft.User.username}</span>
                <span> With <span className='bold'>your holding</span>.</span>
              </p>
              <div className='mt-3'>
                <span>If you buy this NFT with your holdings, your current share will be updated to <span className='bold'>{ vaultPercent }%</span></span>
              </div>
              <div className='heading'>
              </div>
              <div className='d-flex justify-content-center'>
                <button className='btn-main lead m-2' onClick={() => buyNFT(true)}>{"Buy (Holdings)"}</button>
                <button className='btn-main lead m-2' onClick={() => setVaultChecked(true)}>{"Buy (USDC)"}</button>
              </div>
            </> :
            <>
              <p>
                {t("ToPurchase")}{' '}
                <span className='bold'>{nft && nft.title}{' '}</span>
                <span>{t("From")}</span>
                <span className='bold'> {nft && nft.User && nft.User.username}</span>
                <span> with <span className='bold'>USDC</span>.</span>
              </p>
              <div className='heading mt-3'>
              </div>
              {isCouponSet && coupon && <>
                <div className='heading'>
                  <p>{t("Price")}</p>
                  <div className='subtotal'>{Web3.utils.fromWei(nft?.price.toString(), 'mwei')} USDC</div>
                </div>
                <div className='heading'>
                  <p>{t("Discount")}</p>
                  <div className='subtotal'>{Web3.utils.fromWei((nft?.price - price).toString(), 'mwei')} USDC</div>
                </div>
              </>}
              <div className='heading'>
                <p>{t("YouWillPay")}</p>
                <div className='subtotal'>{Web3.utils.fromWei(price.toString(), 'mwei')} USDC</div>
              </div>
              {nft.User?.metamask === companyWalletAddress.toLowerCase() && <div className='heading mt-3'>
                <div className='coupon'>
                  <p>Coupon Code (optional)</p>
                  <input
                    type='text'
                    name='coupon'
                    id='coupon'
                    className='form-control'
                    onChange={(event) => handleSearchCoupon(event.target.value)}
                  />
                  {isCouponError && <p style={{color:'red'}}>{isCouponOverdue ? 'This Coupon Code is expired.' : 'Invalid Coupon.'}</p>}
                </div>
              </div>}
              <button disabled={isFetchingCoupon} className='btn-main lead mb-5' onClick={() => buyNFT(false)}>{t("BuyNow")}</button>
              {/* {
                // eslint-disable-next-line
                <p>The current price is calculated as per USDT / EIV Exchange Rate at the time of loading and will be available for the next 60 seconds. 
                  <a onClick={() => fetchPriceRate()}><u className={classes.refreshLink}>Refresh now</u></a>
                </p>
              } */}
            </>}
          </div>
        </div>
      )}
      <Dialog
          open={showModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
      >
        <div className={classes.root}>
          <div className={classes.topBlock}>
            <CheckCircleOutlinedIcon className={classes.icon} />
          </div>  
          <div className={classes.bottomBlock}>
            <Typography className={classes.title}>
                {t("You need to connect your wallet first!")}
            </Typography>
            <div className={classes.buttonBlock}>
              <button
                className="btn-main"
                onClick={()=>setShowModal(false)}
              >
                {t("Close")} 
              </button>
              <button
                className="btn-main"
                onClick={() => { auth(); setShowModal(false); }}
              >
                {t("Connect")}
              </button>
            </div>
          </div>
        </div>  
      </Dialog>
      <Dialog
          open={showAlertModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
      >
          <div className={classes.root}>
              <div className={classes.topBlock}>
                  <CheckCircleOutlinedIcon className={classes.icon} />
              </div>  
              <div className={classes.bottomBlock}>
                  <Typography className={classes.title}>
                      {alertMessage}
                  </Typography>
                  <div className={classes.alertButtonBlock}>
                      <button
                          className="btn-main"
                          onClick={()=>{setShowAlertModal(false)}}
                      >
                          {t("Close")} 
                      </button>
                  </div>
              </div>
          </div>
      </Dialog>
      <ModalNoMetamask open={serverError} onClose={() => {setServerError(false);}}/>
    </div>
  );
};

export default memo(AssetDetails);
