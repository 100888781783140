import { memo, useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay-ts';
import { ReactNotifications } from 'react-notifications-component';

import 'react-notifications-component/dist/theme.css';

import Clock from '../../components/Clock';
import Footer from '../../components/footer';
import { createGlobalStyle } from 'styled-components';
import Web3 from "web3";
import * as selectors from '../../../../store/selectors';
import * as actions from '../../../../store/actions/thunks';
import * as setActions from '../../../../store/actions';
import api from '../../../../core/api';
import { useParams, useHistory } from 'react-router';
import { debounce } from 'lodash';
import Header from '../../menu/header';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import { Typography, Dialog, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { fetchCurrentAuthor } from "../../../../store/actions/thunks";
import MarketplaceAbi from "../../../../artifacts/contracts/Marketplace.sol/Marketplace.json";
import NFTAbi from "../../../../artifacts/contracts/Collectible.sol/Collectible.json";
import SNAPSHOTAbi from "../../../../artifacts/contracts/MarketplaceSnapshot.sol/MarketplaceSnapshot.json";

import erc20Abi from "../../../../artifacts/contracts/IERC20.sol/IERC20.json";
import ModalError from '../../../../styles/components/Servererror/modalError';
import Nft3dCard from '../../../../components/Cards/nft3DCard';
import useStyles from '../../../../styles/pages/Explore/AssetDetails';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AssetCardFooter from '../../components/AssetCardFooter';
import { login, register } from '../../../../utils/functions/authenticate';
import { EROLES } from "../../../../utils/constants/roles.enum";
import * as CouponService from "../../../../services/CouponService";
import * as DataService from "../../../../services/DataService";
import * as NftService from "../../../../services/NftService";
import * as AuthService from "../../../../services/AuthService";
import Notification from "../../../../utils/functions/notification";
import Allowance from "../../../../utils/functions/allowance";
import enableWeb3 from "../../../../utils/functions/enableWeb3";
import { BigNumber } from '@ethersproject/bignumber';
import { useAccount } from 'wagmi';
import { useWriteContract, useTransactionConfirmations } from 'wagmi';
import { useAppKit } from '@reown/appkit/react';

const GlobalStyles = createGlobalStyle `
  header#myHeader.navbar.white {
    background: #fff;
    border-bottom: solid 1px #dddddd;
  }
  header#myHeader.navbar .search #quick_search{
    color: #20005e;
    background: rgba(255, 255, 255, .1);
    }
    header#myHeader.navbar.white .btn, .navbar.white a{
        color: #20005e;
    }
    header#myHeader.navbar.sticky.white .btn, .navbar.sticky.white a{
        color: #20005e;
    }
    header#myHeader .dropdown-toggle::after{
        color: #20005e;
    }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #8364E2 !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
  }
`;

const AssetDetails = () => {
  const { nftId } = useParams();
  const [marketplaceContract, setMarketplaceContract] = useState();
  const [nftContract, setNftContract] = useState();
  const [snapshotContract, setSnapshotContract] = useState();
  const [eivContract, setEivContract] = useState();
  const [isPriceError, setPriceError] = useState(false);
  const [isForBid, setIsForBid] = useState(false);

  const [isActive, setActive] = useState(false);
  const [isBidAvailable, setBidAvailable] = useState(true);
  const [bidAmount, setBidAmount] = useState();
  const [serviceFee, setServiceFee] = useState(10);
  const [feeScale, setFeeScale] = useState(1000);
  // eslint-disable-next-line
  const [serverError, setServerError] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Please wait...');
  const [vaultAmount, setVaultAmount] = useState(0);
  const [vaultPercent, setVaultPercent] = useState(0);
  const [isVaultChecked, setVaultChecked] = useState(false);
  const [isVault, setIsVault] = useState();
  const [exchangeRate, setExchangeRate] = useState(false);
  const [exchangeRateScale, setExchangeRateScale] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [isCouponError, setCouponError] = useState(false);
  const [isCouponSet, setCouponSet] = useState(false);
  const [isCouponOverdue, setCouponOverdue] = useState(false);
  const [isFetchingCoupon, setFetchingCoupon] = useState(false);

  const nftAddress = process.env.REACT_APP_NFT_ADDRESS;
  const marketplaceAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
  const snapshotAddress = process.env.REACT_APP_SNAPSHOT_CONTRACT;
  const eivAddress = process.env.REACT_APP_EIV_ADDRESS;
  const companyWalletAddress = process.env.REACT_APP_COMPANY_WALLET_ADDRESS;
  const SNOWTRACE_URL = process.env.REACT_APP_SNOWTRACE_URL;

  const { t } = useTranslation();

  const web3 = new Web3(new Web3.providers.HttpProvider(process.env.REACT_APP_AVALANCHE_URL));

  const [price, setPrice] = useState("");
  const [couponPrice, setCouponPrice] = useState(0);
  const [sellPrice, setSellPrice] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [render, setRender] = useState(false);
  const [actionType, setActionType] = useState();
  const [showTransferNft, setShowTransferNft] = useState(false);
  const [isSendClicked, setSendClicked] = useState(false);
  // const [priceRate, setPriceRate] = useState(0);
  const [transferAddress, setTransferAddress] = useState(0);
  const [continents, setContinents] = useState([]);
  const [royalty, setRoyalty] = useState(0);
  const [authorRoyalty, setAuthorRoyalty] = useState(0);
  const [currentSM, setCurrentSM] = useState();
  const [hash, setHash] = useState();
  const { address } = useAccount();
  const { writeContract, isSuccess, isError, data, error } = useWriteContract();
  const { status } = useTransactionConfirmations({hash: hash});
  const { open } = useAppKit();

  const signedUser = useSelector(selectors.user);
  const authorsState = useSelector(selectors.authorsState);
  const authors = authorsState.data ? authorsState.data : [];

  const history = useHistory();
  const classes=useStyles();

  const isVoted = useRef(false);
  const isWishlisted = useRef(false);

  useEffect(() => {
    DataService.fetchContinents().then((res => {
      setContinents(res);
    }))

    // fetchPriceRate();
    // const timer = setTimeout(() => {
    //   fetchPriceRate();
    // }, 60000);
    // return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if(nft && parseFloat(price) <= parseFloat(Web3?.utils?.fromWei(nft.price, 'ether'))) {
      setPriceError(true);
    } else {
      setPriceError(false);
    }
    // eslint-disable-next-line
  }, [price]);

  useEffect(() => {
    if (status === 'success') {
      if(currentSM === 'cancel') {
        const metadata = {
          'txHash': data
        };

        NftService.cancelNFTListing(nft?.id, metadata).then(res => {
          dispatch(actions.fetchNftDetail(nft?.id));
          dispatch(actions.fetchNotifications());
          setActive(false);
          Notification('NFT is not for sale anymore.', true);
        }).catch(error => {
          let msg = (typeof error === 'object') ? error.message : error;
          setActive(false);
          Notification(msg, false);
        });
      }
      else if (currentSM === 'list') {
        const amount = Web3.utils.toWei(sellPrice, 'mwei');
        const metadata = {
          'price': amount,
          'txHash': data
        };
  
        NftService.addNFTListing(nft?.id, metadata).then((res) => {
          dispatch(actions.fetchNftDetail(nft?.id));
          dispatch(actions.fetchNotifications());
          setOpenAddListing(false);
          setActive(false);
          Notification('NFT is now for sale.', true);
        }).catch((error) => {
          let msg = (typeof error === 'object') ? error.message : error;
          setActive(false);
          Notification(msg, false);
        });
      }
      else if (currentSM === 'approve') {
        const amount = Web3.utils.toWei(sellPrice, 'mwei');
        setCurrentSM('list');
        writeContract({ 
          abi: MarketplaceAbi.abi,
          address: marketplaceAddress,
          functionName: 'list',
          args: [
            nft?.tokenId,
            amount
          ],
        });
      }
      else if (currentSM === 'eiv-approve') {
        setCurrentSM('buy-item');
        writeContract({ 
          abi: MarketplaceAbi.abi,
          address: marketplaceAddress,
          functionName: 'buyItem',
          args: [
            nft?.tokenId,
            false,
            isVault,
            coupon ? true : false,
            coupon
          ],
        });
      }
      else if (currentSM === 'buy-item') {
        const metadata = {
          'txHash': data,
          'tokenId': nft.tokenId,
          'isVault': isVault,
          'coupon': coupon
        }
        NftService.buyNFT(nft.id, metadata).then((res) => {
          dispatch(actions.fetchNotifications());
          dispatch(actions.fetchNftDetail(nftId));
          setOpenCheckout(false);
          setActive(false);
          Notification(`You purchased an item.`, true);
        }).catch((error) => {
          let msg = (typeof error === 'object') ? error.message : error;
          setActive(false);
          Notification(msg, false);
        });
      }
      else if (currentSM === 'transfer-approve') {
        setCurrentSM('manual-transfer');
        writeContract({ 
          abi: MarketplaceAbi.abi,
          address: marketplaceAddress,
          functionName: 'manualTransfer',
          args: [
            nft?.tokenId,
            transferAddress.metamask
          ],
        });
      }
      else if (currentSM === 'manual-transfer') {
        const metadata = {
          'txHash': data,
          'receiverId': transferAddress.id,
        };
        NftService.transferNFT(nft.id, metadata).then((res) => {
          setShowTransferNft(false)
          setActive(false);
  
          dispatch(actions.fetchNotifications());
          dispatch(actions.fetchNftDetail(nft?.id));
  
          Notification(`Your NFT has been transferred successfully.`, true);
        }).catch((error) => {
          let msg = (typeof error === 'object') ? error.message : error;
          setActive(false);
          setShowTransferNft(false)
          Notification(msg, false);
        });
      }
    }
    else if (status === 'error') {
      if (currentSM === 'cancel') {
        setActive(false);
        Notification('Failed to cancel listing this NFT.', false);
      }
      else if (currentSM === 'list') {
        setActive(false);
        Notification('Failed to add listing this NFT.', false);
      }
      else if (currentSM === 'approve') {
        setActive(false);
        Notification('Failed to approve this NFT.', false);
      }
      else if (currentSM === 'eiv-approve') {
        setActive(false);
        Notification('Not sufficient allowance.', false);
      }
      else if (currentSM === 'buy-item') {
        setActive(false);
        Notification('Failed to purchasing this NFT.', false);
      }
      else if (currentSM === 'transfer-approve') {
        setActive(false);
        Notification('Failed to approving this NFT.', false);
      }
      else if (currentSM === 'manual-transfer') {
        setActive(false);
        Notification('Failed to transferring this NFT.', false);
      }
    }
  }, [status]);

  useEffect(() => {
    if (isSuccess) {
      setHash(data);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      if (currentSM === 'cancel') {
        setActive(false);
        Notification('Failed to cancel listing this NFT.', false);
      }
      else if (currentSM === 'list') {
        setActive(false);
        Notification('Failed to add listing this NFT.', false);
      }
      else if (currentSM === 'approve') {
        setActive(false);
        Notification('Failed to approve this NFT.', false);
      }
      else if (currentSM === 'eiv-approve') {
        setActive(false);
        Notification('Not sufficient allowance.', false);
      }
      else if (currentSM === 'buy-item') {
        setActive(false);
        Notification('Failed to purchasing this NFT.', false);
      }
      else if (currentSM === 'transfer-approve') {
        setActive(false);
        Notification('Failed to approving this NFT.', false);
      }
      else if (currentSM === 'manual-transfer') {
        setActive(false);
        Notification('Failed to transferring this NFT.', false);
      }
    }
  }, [isError]);

  // const fetchPriceRate = () => {
  //   DataService.fetchExchangeData().then((res => {
  //     setPriceRate(res);
  //   }));
  // }

  const dispatch = useDispatch();
  let nft = useSelector(state=>state.NFT.nftDetail.data);

  const fetchCoupon = (value, amount) => {
    setFetchingCoupon(true);
    if(value === '' || value === null) {
      setCouponError(false);
      setCouponSet(false);
      setCoupon('');
      setCouponPrice(amount);
      setFetchingCoupon(false);
      setCouponOverdue(false);
    }
    else {
      CouponService.fetchCoupon(value).then(res => {
        if (res) {
          if (res === true) {
            setCouponError(true);
            setCouponSet(false);
            setCoupon('');
            setCouponPrice(amount);
            setFetchingCoupon(false);
            setCouponOverdue(true);
          }
          else {
            setCouponError(false);
            setCouponSet(true);
            setCoupon(res.id);
            setCouponPrice(parseInt(amount * (1 - res.discount / 100)));
            setFetchingCoupon(false);
            setCouponOverdue(false);
          }
        } else {
          setCouponError(true);
          setCouponSet(false);
          setCoupon('');
          setCouponPrice(amount);
          setFetchingCoupon(false);
          setCouponOverdue(false);
        }
      });
    }
  }

  const [debounceFetchCoupon] = useState(() => debounce(fetchCoupon, 1000));

  const [openCheckout, setOpenCheckout] = useState(false);
  const [openCheckoutbid, setOpenCheckoutbid] = useState(false);
  const [openWithdrawBid, setOpenWithdrawBid] = useState(false);
  const [openAddListing, setOpenAddListing] = useState(false);
  const [countLike, setCountLike] = useState();
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [transferAddressList, setTransferAddressList] = useState([]);
  const [searchAddress, setSearchAddress] = useState();

  const fetchAddress = (value) => {
    setTransferAddressList([]);
    if(value === '' || value === null) {
      setTransferAddress(0);
    }
    else {
      AuthService.fetchUsersByAddress({address: value}).then(res => {
        setTransferAddressList(res);
      });
    }
  }

  const [debounceFetchAddress] = useState(() => debounce(fetchAddress, 1000));

  useEffect(() => {
    init().then((res) => {
      dispatch(actions.fetchNftDetail(nftId));
    });
    // dispatch(actions.fetchAssetsBreakdown(nftId));
    // eslint-disable-next-line
  }, [dispatch, nftId]);

  useEffect(() => {
    setCoupon('');
    setCouponPrice(parseFloat(nft?.price));
    setCouponError(false);
  }, [openCheckout]);

  useEffect(() => {
    if(openAddListing) {
      marketplaceContract.methods.getNFTcommission(nft.tokenId).call().then(res => {
        const [_, commission, royaltyAmount, authorRoyaltyAmount] = Object.values(res).map(Number);
        setRoyalty(royaltyAmount / 10);
        setAuthorRoyalty(authorRoyaltyAmount / 10);
      })
    }
  }, [openAddListing])

  useEffect(() => {   
    setCountLike(nft?.NftLikes?.length); 
    
    if (nft && marketplaceContract) {
      setCouponPrice(parseFloat(nft?.price));

      marketplaceContract.methods.EXCHANGE_RATE_SCALE().call().then(result => {
        setExchangeRateScale(parseFloat(result));
      });

      marketplaceContract.methods.exchangeRate().call().then(result => {
        setExchangeRate(parseFloat(result));
      });

      if(nft.NftLikes) {
        for(let i=0; i<nft.NftLikes.length; i++) {
            if(signedUser.metamask === nft.NftLikes[i].User.metamask.toLowerCase()) {
              isVoted.current = true;
            }
        }
      }

      if(nft.NftWishlists) {
          for(let i=0; i<nft.NftWishlists.length; i++) {
              if(signedUser.metamask === nft.NftWishlists[i].User.metamask.toLowerCase()) {
                isWishlisted.current = true;
              }
          }
      }
    }
    const bid = nft?.Bids?.find(o => o.User.metamask === signedUser.metamask && o.status === 'PENDING');
    if(bid) {
      setBidAvailable(false);
    }

  }, [nft, marketplaceContract]);

  useEffect(() => {
    if(signedUser.id) {
      dispatch(fetchCurrentAuthor(signedUser.id));
    }
  }, [dispatch, signedUser.id]);

  const init = async() => {
    // if(window.ethereum) {
      const marketplaceContract = new web3.eth.Contract(MarketplaceAbi.abi, marketplaceAddress);
      const nftContract = new web3.eth.Contract(NFTAbi.abi, nftAddress);
      const eivContract = new web3.eth.Contract(erc20Abi.abi, eivAddress);
      const snapshotContract = new web3.eth.Contract(SNAPSHOTAbi.abi, snapshotAddress);
  
      setMarketplaceContract(marketplaceContract);
      setNftContract(nftContract)
      setSnapshotContract(snapshotContract);
      setEivContract(eivContract);
    // }
  }

  const auth = async () => {
    open();
    // if (window.ethereum) {
      // login(dispatch, setActions.setLoading, setActions.setUser, history, address);
    // }
  };

  useEffect(() => {
    setRender(!render);
  }, [isVoted.current, isWishlisted.current]);

  const handleLike = async (userData) => {        
    setActionType();

    if(nft.Likes) {
        for(let i=0; i<nft.Likes.length; i++) {
            if(userData.metamask === nft.Likes[i].User.metamask.toLowerCase()) {
              isVoted.current = true;
            }
        }
    }

    if(!isVoted.current) {
        dispatch(setActions.setLoading(true));
        const res = await NftService.setNftLike(nft?.Asset?.id, nft?.id);
        try {
          setCountLike(res.likes);
          isVoted.current = true;
          setShowAlertModal(true);
          setAlertMessage('Your like has been added.');
          dispatch(actions.fetchNotifications());
        } catch(e) {
            alert(e);
        }
        dispatch(setActions.setLoading(false));
    }
    else {
        dispatch(setActions.setLoading(true));
        const res = await NftService.setNftDislike(nft?.id);
        try {
          setCountLike(res.likes);
          isVoted.current = false;
          dispatch(actions.fetchNotifications());
          setShowAlertModal(true);
          setAlertMessage('Your like has been removed.');
        } catch(e) {
          alert(e);
      }
      dispatch(setActions.setLoading(false));
    }
  }

  const onClickLike = () => {
      if(signedUser.id) {
          handleLike(signedUser);
      }
      else {
          setShowModal(true);
          setActionType('like');
      }
  }

  const onClickBasket = () => {
    if(signedUser.id) {
        handleWishlist(signedUser);
    }
    else {
        setShowModal(true);
        setActionType('wishlist');
    }
  }

  const handleWishlist = async (userData) => {
    if(nft.Wishlists) {
        for(let i=0; i<nft.Wishlists.length; i++) {
            if(userData.metamask === nft.Wishlists[i].User.metamask.toLowerCase()) {
              isWishlisted.current = true;
            }
        }
    }

    if(!isWishlisted.current) {
      dispatch(setActions.setLoading(true));
      try {
        const res = await NftService.setNftWishlist(nft?.Asset?.id, nft?.id);
        dispatch(actions.fetchNotifications());
        isWishlisted.current = true;
        setShowAlertModal(true);
        setAlertMessage('This NFT has been added to your wishlist.');
      } catch(e) {
        alert(e);
      }
      dispatch(setActions.setLoading(false));
    }
    else {
      dispatch(setActions.setLoading(true));
      try {
        const res = await NftService.setNftDiswishlist(nft?.id);
        dispatch(actions.fetchNotifications());
        isWishlisted.current = false;
        setShowAlertModal(true);
        setAlertMessage('This NFT has been removed from your wishlist.');
      } catch(e) {
        alert(e);
      }
      dispatch(setActions.setLoading(false));
    }
  }

  const handleBuyNow = () => {
    if (signedUser.id) {
      dispatch(setActions.setLoading(true));
      AuthService.sessionCheck().then((res) => {
        dispatch(setActions.setLoading(false));
        snapshotContract.methods.getInfo(signedUser.metamask).call().then(result => {
          setOpenCheckout(true);
          setCoupon("");
          setVaultChecked(false);
          setVaultAmount(parseFloat(Web3.utils.fromWei(result[0], 'mwei')));

          snapshotContract.methods.EQUITY_INITIAL_SUPPLY().call().then(initialSupply => {
            const nftPrice = BigNumber.from(nft?.price);
            const balance = BigNumber.from(result[0]);
            const exchangeRateScaled = nftPrice.mul(exchangeRateScale).div(exchangeRate);
            const subtracted = balance.sub(exchangeRateScaled);
            const divided = parseFloat(Web3.utils.fromWei(subtracted.toString(), 'mwei')) * 100 / parseFloat(Web3.utils.fromWei(initialSupply, 'mwei'));
            setVaultPercent(divided);
          })
        });
      })
      .catch(err => {
        dispatch(setActions.setLoading(false));
        if(err.response?.status == '401') {
          dispatch(actions.setIsTimeout(true));
        }
      })
    } else {
      setShowModal(true);
    }
  };

  const handleBid = () => {
    if (signedUser.id) {
        setOpenCheckoutbid(true);
        setOpenCheckout(false);
        setOpenWithdrawBid(false);
        setOpenAddListing(false);
    } else {
      setShowModal(true);
    }
  };

  const handleAddListing = () => {
    if (signedUser.id) {
      dispatch(setActions.setLoading(true));
      setLoadingMessage("Please wait while you list your NFT for sale.")
      AuthService.sessionCheck().then(async (res) => {
        dispatch(setActions.setLoading(false));
        setOpenAddListing(true);
        setOpenCheckoutbid(false);
        setOpenCheckout(false);
        setOpenWithdrawBid(false);
      })
      .catch(err => {
        dispatch(setActions.setLoading(false));
        if(err.response?.status == '401') {
          dispatch(actions.setIsTimeout(true));
        }
      })
    } else {
      setShowModal(true);
    }
  }

  const handleWithdrawBid = () => {
    if (signedUser.id) {
      setOpenWithdrawBid(true);
      setOpenCheckoutbid(false);
      setOpenCheckout(false);
      setOpenAddListing(false);
    } else {
      setShowModal(true);
    }
  }

  const cancelListing = async () => {
    dispatch(setActions.setLoading(true));
    AuthService.sessionCheck().then(async (res) => {
      dispatch(setActions.setLoading(false));
      setOpenWithdrawBid(false);
      setActive(true);

      // const enabled = await enableWeb3();
      // if (!enabled) {
      //   Notification('Waiting for metamask sign in.', false);
      //   return
      // }

      try {
        // const account = signedUser.metamask;
        // const txRes = await marketplaceContract.methods.cancel(nft.tokenId).send({from: account});
        setCurrentSM('cancel');
        writeContract({ 
          abi: MarketplaceAbi.abi,
          address: marketplaceAddress,
          functionName: 'cancel',
          args: [
            nft.tokenId
          ],
        });
      } catch (error) {
        let msg = (typeof error === 'object') ? error.message : error;
        setActive(false);
        Notification(msg, false);
        return false
      }
    })
    .catch(err => {
      dispatch(setActions.setLoading(false));
      if(err.response?.status == '401') {
        dispatch(actions.setIsTimeout(true));
      }
    })
  }

  const addListing = async () => {
    setActive(true);
    // const enabled = await enableWeb3();
    // if (!enabled) {
    //   Notification('Waiting for metamask sign in.', false);
    //   return
    // }
    // const account = signedUser.metamask;
    try {
      const amount = Web3.utils.toWei(sellPrice, 'mwei');

      const approvedAddress = await nftContract.methods.getApproved(nft?.tokenId).call();
      if (approvedAddress !== marketplaceAddress) {
        // await nftContract.methods.approve(marketplaceAddress, nft?.tokenId).send({from: account});
        setCurrentSM('approve');
        writeContract({ 
          abi: NFTAbi.abi,
          address: nftAddress,
          functionName: 'approve',
          args: [
            marketplaceAddress,
            nft?.tokenId
          ],
        });
      }
      else {
        setCurrentSM('list');
        writeContract({ 
          abi: MarketplaceAbi.abi,
          address: marketplaceAddress,
          functionName: 'list',
          args: [
            nft?.tokenId,
            amount
          ],
        });
      }
      // const txRes = await marketplaceContract.methods.list(nft?.tokenId, amount).send({from: account});
    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
      return false
    }
  }

  const withdrawBid = async () => {}

  const placeBid = async () => {}

  const buyNFT = async (vault) => {
    setActive(true);
    setIsVault(vault);
    setLoadingMessage("Please wait while you purchase the item");
    // const enabled = await enableWeb3();
    // if (!enabled) {
    //   Notification('Waiting for metamask sign in.', false);
    //   return
    // }

    try {
      // let amount = Web3.utils.toWei('0.001', 'mwei');
      let amount = Web3.utils.toWei('0', 'mwei');
      if (!vault) {
        amount = Web3.utils.toBN(couponPrice).add(Web3.utils.toBN(price));
      }

      // await Allowance(eivContract, account, marketplaceAddress, amount);
      setCurrentSM('eiv-approve');
      writeContract({ 
        abi: erc20Abi.abi,
        address: eivAddress,
        functionName: 'approve',
        args: [
          marketplaceAddress,
          amount,
        ],
      });
    } catch (error) {
      let msg = (typeof error === 'object') ? error.message : error;
      setActive(false);
      Notification(msg, false);
    }
  }

  const sendManualTransfer = async () => {
    dispatch(setActions.setLoading(true));
    AuthService.sessionCheck().then(async (res) => {
      dispatch(setActions.setLoading(false));
      // const enabled = await enableWeb3();
      // if (!enabled) {
      //   Notification('Waiting for metamask sign in.', false);
      //   return
      // }
      setActive(true);
      try {
        const account = signedUser.metamask;
        const receiver = transferAddress.metamask;
        // await nftContract.methods.approve(marketplaceAddress, nft?.tokenId).send({from: account});
        setCurrentSM('transfer-approve');
        writeContract({ 
          abi: NFTAbi.abi,
          address: nftAddress,
          functionName: 'approve',
          args: [
            marketplaceAddress,
            nft?.tokenId
          ],
        });
      } catch (error) {
        let msg = (typeof error === 'object') ? error.message : error;
        setActive(false);
        setShowTransferNft(false)
        Notification(msg, false);
      }
    })
    .catch(err => {
      dispatch(setActions.setLoading(false));
      if(err.response?.status == '401') {
        dispatch(actions.setIsTimeout(true));
      }
    })
  }

  const handleAuth = async () => {
    if(window.ethereum) {
      const userData = await login(dispatch, setActions.setLoading, setActions.setUser, history, setServerError);
      setShowModal(false);
      if(actionType === 'like') {
          handleLike(userData);
      }
      if(actionType === 'wishlist') {
          handleWishlist(userData);
      }
    }
    else {
      var win = window.open('https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en', '_blank');
      win.focus();
    }
  }

  const handleSearchCoupon = (value) => {
    debounceFetchCoupon(value, parseInt(nft?.price));
  }

  const handleSearchAddress = (value) => {
    setSearchAddress(value);
    debounceFetchAddress(value);
  }

  return (
    <div>
      <LoadingOverlay
        active={isActive}
        spinner
        text={loadingMessage}
        className='centeredOverlay'>
      <GlobalStyles />
      {/* <StyledHeader theme={theme} /> */}
      <Header className='HOME' />
      <ReactNotifications />
      <section className='container'>
        <div className='row mt-md-5 pt-md-4'>
          <div className='col-md-7 text-center'>
            <div style={{border: '1px solid #DDD', borderRadius: 15}}>
              { nft && nft.Asset.gltf && nft.id === parseInt(nftId) &&
                  <Nft3dCard src={`${api.server3dnTech}/${nft.Asset.directory}/${nft.Asset.gltf}`} height={450} border={true} ratio={0.7}/>
              }
            </div>
            { nft && !nft.Asset.gltf && <img src={api.ipfsURL + nft.Asset.image} className="img-fluid img-rounded mb-sm-30" alt=""/>}
            {nft && nft.Asset.gltf && <p style={{fontWeight: 700}}>To view this 3D Assets, click on the image and move your mouse. You can also mouse scroll to zoom-in and zoom-out in the preview</p>}
            {nft && nft.Asset.gltf && <p style={{fontSize: 14}}>Disclaimer: The 3D Asset rendering is subject to differences with the real version available inside the Metaverse. Please check preview Video to have an exact render of the asset.</p>}
          </div>
          <div className='col-md-5'>
            <div className='item_info'>
              {nft && nft.item_type === 'on_auction' && (
                <>
                  {t("AuctionEnd")}
                  <div className='de_countdown'>
                    <Clock deadline={nft.deadline} />
                  </div>
                </>
              )}
              <h2>{nft && nft.Asset.title}</h2>
              <div className='item_info_counts nft__item_info'>
                {/* <div className='item_info_type'>
                  <i className='fa fa-image'></i>
                  {nft && nft.category}
                </div>
                <div className='item_info_views'>
                  <i className='fa fa-eye'></i>
                  {nft && nft.views}
                </div> */}
                <div className={isVoted.current ? "details__item_liked" : "details__item_like"} onClick={() => onClickLike()}>
                  <i className="fa fa-heart"></i><span>{countLike}</span>
                </div>
                <div className={isWishlisted.current ? "details__item_liked" : "details__item_like"} onClick={() => onClickBasket()}>
                  <i className="fa fa-shopping-basket"></i>
                </div> 
              </div>
              {/* <p>Price in EIV : {nft && nft.price && serviceFee && feeScale && Web3.utils.fromWei(BigNumber.from(nft.price, 'mwei').add(BigNumber.from(nft.price).mul(serviceFee).div(feeScale)).toString())} EIV</p>
              <p>Price in USDT : {nft && nft.price && serviceFee && feeScale && (Web3.utils.fromWei(BigNumber.from(nft.price, 'mwei').add(BigNumber.from(nft.price).mul(serviceFee).div(feeScale)).toString())*priceRate).toFixed(2)} USDT</p> */}
              <p>Item ID : #{nft && nft.itemId}</p>
              <p>On-Chain Reference: <a href={`${SNOWTRACE_URL}${nftAddress}/${nft?.tokenId}`} target="_blank"><u>#{nft && nft?.tokenId}</u></a></p> 
              <p>Price in USDC : {nft && nft.price && Web3.utils.fromWei(nft.price, 'mwei')} USDC</p>
              {/* <p>Price in USDT : {nft && nft.price && (Web3.utils.fromWei(nft.price, 'mwei') * priceRate).toFixed(2)} USDT</p> */}
              {/* <p>1 EIV = {priceRate} USDT</p> */}
              {nft?.variable && nft?.variable?.find(item => item.variable === 'Continent') && <p><strong>Continent: {continents.find(continent => continent.id === nft?.variable?.find(item => item.variable === 'Continent')?.value)?.name }</strong></p>}
              {nft?.variable && nft?.variable?.find(item => item.variable === 'Level') && <p><strong>Level: {nft?.variable?.find(item => item.variable === 'Level')?.value}</strong></p>}
              <div className='d-flex flex-row'>
                {nft && nft.User && <div className='mr40'>
                  <h6>{t("Owner")}</h6>
                  <div className='item_author'>
                    <div className='author_list_pp'>
                      <span>
                        { nft && nft.User && nft.User.picture ?
                          <img
                            className='lazy'
                            src={
                              nft &&
                              nft.User &&
                              `${api.baseUrl}/${nft.User.picture}`
                            }
                            alt=''
                            onClick={() => {
                              if(authors.findIndex(e => e.id === nft?.User?.id) > -1)
                                  history.push({pathname:`/author/${nft.User.id}`});
                            }}
                          />
                          // <i className='fa fa-check'></i>
                          :
                          <img className="lazy" src="/img/author/author-11.png" alt="" onClick={() => {
                            if(authors.findIndex(e => e.id === nft?.User?.id) > -1)
                                history.push({pathname:`/author/${nft.User.id}`});
                            }}/>
                        }
                      </span>
                    </div>
                    <div className='author_list_info'>
                      <span>{nft && nft.User && nft.User.username}</span>
                    </div>
                  </div>
                </div>}
                {/* <div className='mr40'>
                  <h6>{t("Collection")}</h6>
                  <div className='item_author'>
                    <div className='author_list_pp'>
                      <span>
                        <img
                          className='lazy'
                          src={
                            nft &&
                            nft.author &&
                            api.baseUrl + nft.author.avatar.url
                          }
                          alt=''
                        />
                        <i className='fa fa-check'></i>
                      </span>
                    </div>
                    <div className='author_list_info'>
                      <span>{nft && nft.author && nft.author.username}</span>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className='spacer-40'></div>

              <div className='de_tab'>
                {/* <ul className='de_nav'>
                  <li id='Mainbtn0' className='active'>
                    <span onClick={handleBtnClick0}>{t("Details")}</span>
                  </li>
                  { nft?.Bids?.length > 0 &&
                    <li id='Mainbtn' className=''>
                      <span onClick={handleBtnClick}>{t("Bids")}</span>
                    </li>
                  }
                  <li id='Mainbtn1' className=''>
                    <span onClick={handleBtnClick1}>{t("History")}</span>
                  </li>
                </ul> */}

                <div className='de_tab_content'>
                  {/* {openMenu0 && (
                    <div className='tab-1 onStep fadeIn'>
                      {nft && nft.Asset.description}
                    </div>
                  )}

                  {openMenu && (
                    <div className='tab-1 onStep fadeIn'>
                      {nft && user && nft.Bids && 
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">No</th>
                              <th scope="col">Price</th>
                              <th scope="col">From</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {nft.Bids.map((bid, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td><strong>{Web3.utils.fromWei(bid.price, 'mwei')}</strong> EIV</td>
                              <td><strong>{bid.User.username}</strong></td>
                              {nft.User && user.get("ethAddress") == nft.User.metamask &&
                              <td>
                                <div className='p_list_info'>
                                    <button className='btn-main' onClick={() => buyForBid(bid)}>accept</button>
                                </div>
                              </td>}
                            </tr>
                          ))}
                          </tbody>
                        </table>}
                    </div>
                  )} */}

                  {/* {openMenu1 && (
                    <div className='tab-2 onStep fadeIn'>
                      {nft && user && nft.SalesHistories && 
                        <table className="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">No</th>
                              <th scope="col">From</th>
                              <th scope="col">To</th>
                              <th scope="col">Price</th>
                              <th scope="col">Currency</th>
                              <th scope="col">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {nft.SalesHistories.map((history, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td><strong>{history.Seller.username}</strong></td>
                              <td><strong>{history.Buyer.username}</strong></td>
                              <td><strong>{Web3.utils.fromWei(history.buyingPrice, 'mwei')}</strong></td>
                              <td><strong>{history.buyingCurrency}</strong></td>
                              <td><strong>{history.createdAt}</strong></td>
                            </tr>
                          ))}
                          </tbody>
                        </table>}
                    </div>
                  )} */}

                  {/* button for checkout */}
                  <div className='mt-5'>
                    {nft && nft?.User?.metamask !== signedUser?.metamask && !nft?.isForBid && nft?.listedItem && <button className='btn-main lead mb-1 mr15 nft-detail-button' onClick={handleBuyNow}>
                      {t("BuyNow")}
                    </button>}
                    {nft && nft?.User?.metamask !== signedUser?.metamask && nft?.isForBid && isBidAvailable &&
                      <button
                        className='btn-main lead mb-1 mr15 nft-detail-button'
                        onClick={handleBid}
                      >
                      {t("AddBid")}
                      </button>
                    }
                    {nft && nft?.User?.metamask === signedUser?.metamask && nft?.listedItem && nft?.Bids?.length === 0 && <button
                        className='btn-main lead mb-1 mr15 nft-detail-button'
                        onClick={cancelListing}
                      >Do not Sell</button>}

                    {nft && nft?.User?.metamask === signedUser?.metamask && !nft?.listedItem && <button
                        className='btn-main lead mb-1 mr15 nft-detail-button'
                        onClick={handleAddListing}
                      >Want to Sell</button>}

                    {nft && signedUser.id && nft?.User?.metamask === signedUser?.metamask && signedUser.roleId === EROLES.ADMIN && !nft?.listedItem && signedUser?.metamask === companyWalletAddress.toLowerCase() && <button
                        className='btn-main lead mb-1 mr15 nft-detail-button'
                        onClick={() => setShowTransferNft(true)}
                      >Transfer this NFT</button>}

                    {!isBidAvailable &&
                      <button
                        className='btn-main lead mb-1 mr15 nft-detail-button'
                        onClick={handleWithdrawBid}
                      >
                        Withdraw your Bid
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          {nft && <div className='col-md-12'>
            <AssetCardFooter description={nft?.Asset?.description} variable={nft?.variable} continents={continents} histories={nft.histories} preview={api.server3dnTech + '/' + nft?.Asset?.directory + '/' + nft?.Asset?.directory + '.png'} video={nft?.Asset?.video} media={nft?.Asset?.media} mediaFile={nft?.Asset?.mediaFile}/>
          </div>}
        </div>
      </section>
      <Footer />
      </LoadingOverlay>
      {openCheckout && (
        <div className='checkout'>
          <div className='maincheckout'>
            <button
              className='btn-close'
              onClick={() => setOpenCheckout(false)}
            >
              x
            </button>
            <div className='heading'>
              <h3>{t("Checkout")}</h3>
            </div>
            {Web3.utils.fromWei(nft?.price, 'mwei') * exchangeRateScale / exchangeRate < vaultAmount && isVaultChecked === false ?
            <>
              <p>
                {t("ToPurchase")}{' '}
                <span className='bold'>{nft && nft.Asset.title}{' '}</span>
                <span>{t("From")}</span>
                <span className='bold'> {nft && nft.User && nft.User.username}</span>
                <span> With <span className='bold'>your holding</span>.</span>
              </p>
              <div className='mt-3'>
                <span>If you buy this NFT with your holdings, your current share will be updated to <span className='bold'>{ vaultPercent }%</span></span>
              </div>
              <div className='heading'>
              </div>
              <div className='d-flex justify-content-center'>
                <button className='btn-main lead m-2' onClick={() => buyNFT(true)}>{"Buy (Holdings)"}</button>
                <button className='btn-main lead m-2' onClick={() => setVaultChecked(true)}>{"Buy (USDC)"}</button>
              </div>
              </> : 
              <>
                <p>
                  {t("ToPurchase")}{' '}
                  <span className='bold'>{nft && nft.Asset.title}{' '}</span>
                  <span>{t("From")}</span>
                  <span className='bold'> {nft && nft.User && nft.User.username}</span>
                  <span> with <span className='bold'>USDC</span>.</span>
                </p>
                {/* <div className='detailcheckout mt-4'>
                  <div className='listcheckout'>
                    <h6>
                    {t("EnterQuantity")}{' '}
                      <span className='color'>10 {' '}{t("Available")}</span>
                    </h6>
                    <input
                      type='text'
                      name='buy_now_qty'
                      id='buy_now_qty'
                      className='form-control'
                    />
                  </div>
                </div> */}
                <div className='heading mt-3'>
                  {/* <p>{t("YourBlance")}</p> */}
                  {/* <div className='subtotal'>10.67856 ETH</div> */}
                  {/* <div className='subtotal'> {balance ? balance.formatted : ''}</div> */}
                </div>
                {/* <div className='heading'>
                  <p>Price: </p>
                  <div className='subtotal'>{nft && nft.price && serviceFee && feeScale && Web3.utils.fromWei((parseFloat(nft.price)).toString(), 'mwei')} USDC</div>
                </div> */}
                {isCouponSet && coupon && <>
                  <div className='heading'>
                    <p>{t("Price")}</p>
                    <div className='subtotal'>{Web3.utils.fromWei(nft?.price.toString(), 'mwei')} USDC</div>
                  </div>
                  <div className='heading'>
                    <p>{t("Discount")}</p>
                    <div className='subtotal'>{Web3.utils.fromWei((nft?.price - couponPrice).toString(), 'mwei')} USDC</div>
                  </div>
                </>}
                <div className='heading'>
                  <p>{t("YouWillPay")}</p>
                  {/* <div className='subtotal'>{nft && nft.price && Web3.utils.fromWei((parseFloat(nft.price) + parseFloat(nft.price*serviceFee/feeScale)).toString(), 'mwei')} EIV / {nft && nft.price && Web3.utils.fromWei((parseFloat(nft.price*priceRate) + parseFloat(nft.price*priceRate*serviceFee/feeScale)).toString(), 'mwei')} USDT</div> */}
                  <div className='subtotal'>{Web3.utils.fromWei(couponPrice.toString(), 'mwei')} USDC</div>
                </div>
                {nft.User?.metamask === companyWalletAddress.toLowerCase() && <div className='heading mt-3'>
                  <div className='coupon'>
                    <p>Coupon Code (optional)</p>
                    <input
                      type='text'
                      name='coupon'
                      id='coupon'
                      className='form-control'
                      onChange={(event) => handleSearchCoupon(event.target.value)}
                    />
                    {isCouponError && <p style={{color:'red'}}>{isCouponOverdue ? 'This Coupon Code is expired.' : 'Invalid Coupon.'}</p>}
                  </div>
                </div>}
                <button disabled={isFetchingCoupon} className='btn-main lead mb-5' onClick={() => buyNFT(false)}>{t("BuyNow")}</button>
                {/* {
                  // eslint-disable-next-line
                  <p>The current price is calculated as per USDT / EIV Exchange Rate at the time of loading and will be available for the next 60 seconds. <a onClick={() => fetchPriceRate()}><u className={classes.refreshLink}>Refresh now</u></a></p>
                } */}
              </>}
              </div>
            
        </div>
      )}
      {openCheckoutbid && (
        <div className='checkout'>
          <div className='maincheckout'>
            <button
              className='btn-close'
              onClick={() => setOpenCheckoutbid(false)}
            >
              x
            </button>
            <div className='heading'>
              <h3>{t("PlaceBid")}</h3>
            </div>
            <p>
            {t("ToPurchase")}{' '}
              <span className='bold'>{nft && nft.Asset.title}{' '}</span>
              <span>{t("From")}</span>
              <span className='bold'> {nft && nft.User && nft.User.username}</span>
            </p>
            <p>
              <span>Price: {nft && Web3.utils.fromWei(nft.price, 'mwei')} USDC</span>
            </p>
            <div className='detailcheckout mt-4'>
              <div className='listcheckout'>
                <h6>{t("YourBid")} (USDC)</h6>
                <input type='number' step={0.01} className='form-control' onChange={(event) => setPrice(event.target.value)}/ >
                {isPriceError && <p style={{color:'red'}}>Bid price should be higher than {Web3.utils.fromWei(nft.price, 'mwei')} USDC.</p>}
              </div>
            </div>
            {/* <div className='detailcheckout mt-3'>
              <div className='listcheckout'>
                <h6>
                 {t("EnterQuantity")}{' '}
                  <span className='color'>10 {' '}{t("Available")}</span>
                </h6>
                <input
                  type='text'
                  name='buy_now_qty'
                  id='buy_now_qty'
                  className='form-control'
                />
              </div>
            </div> */}
            <div className='heading mt-3'>
              {/* <p>{t("YourBlance")}</p>
              <div className='subtotal'>{balance ? balance.formatted : ''}</div> */}
              {/* <div className='subtotal'>10.67856 ETH</div> */}
            </div>
            <div className='heading'>
              <p>{t("ServiceFee")} {serviceFee / 10}%</p>
              <div className='subtotal'>{nft && price*serviceFee/feeScale} USDC</div>
            </div>
            <div className='heading'>
              <p>{t("YouWillPay")}</p>
              <div className='subtotal'>{nft && price && (parseFloat(price) + parseFloat(price*serviceFee/feeScale)).toFixed(3)} USDC</div>
            </div>
            <button className='btn-main lead mb-5' onClick={placeBid} disabled={!price || isPriceError}>{t("AddBid")}</button>
          </div>
        </div>
      )}
      {openWithdrawBid && (
        <div className='checkout'>
          <div className='maincheckout'>
            <button
              className='btn-close'
              onClick={() => setOpenWithdrawBid(false)}
            >
              x
            </button>
            <div className='heading'>
              <h3>Withdraw your bid</h3>
            </div>
            <p>
              <span>Price: {nft && Web3.utils.fromWei(bidAmount, 'mwei')} USDC</span>
            </p>
            <div className='heading mt-3'>
              {/* <p>{t("YourBlance")}</p>
              <div className='subtotal'>{balance ? balance.formatted : ''}</div> */}
            </div>
            <div className='heading'>
              <p>You will be paid</p>
              <div className='subtotal'>{nft && Web3.utils.fromWei(bidAmount, 'mwei')} USDC</div>
            </div>
            <button className='btn-main lead mb-5' onClick={withdrawBid}>Withdraw</button>
          </div>
        </div>
      )}
      {openAddListing && nft && (
        <div className='checkout'>
          <div className='maincheckout'>
            <button
              className='btn-close'
              onClick={() => setOpenAddListing(false)}
            >
              x
            </button>
            <div className='heading'>
              <h3>Sell My NFT</h3>
            </div>
            <p>
              <span className='bold'>{nft && nft.Asset.title}{' '}</span>
            </p>
            <p>
              <span>{nft && nft.Asset.description.substring(0, 200) + '...'}</span>
            </p>
            <div className='detailcheckout mt-4'>
              <div className='listcheckout'>
                <div className="de_tab tab_methods">
                  <ul className="de_nav">
                    <li className={isForBid ? null : "active"} onClick={() => setIsForBid(false)}><span><i className="fa fa-tag"></i>{t("FixedPrice")}</span>
                    </li>
                    {/* <li className={isForBid ? "active" : null} onClick={() => setIsForBid(true)}><span><i className="fa fa-users"></i>{t("OpenForBids")}</span>
                    </li> */}
                  </ul>
                </div>
                <div className='price_container' style={{marginTop: 20}}>
                  <h6 style={{lineHeight: 0}}>Enter the price</h6>
                </div>
                <input
                  type='text'
                  name='buy_now_qty'
                  id='buy_now_qty'
                  className='form-control'
                  style={{marginBottom: 5}}
                  onChange={(event) => setSellPrice(event.target.value)}
                />
                  <div className='heading'>
                    <p>{isForBid ? 'Set the minimum bid price for this NFT : ' : 'Set the selling price for the NFT : '}</p>
                    <div className='subtotal'>{`${sellPrice} ` + (sellPrice ? 'USDC' : '')}</div>
                  </div>
              </div>
            </div>
            <div className='heading'>
              <p>Selling a NFT is subject to Royalties as per our <a href="http://earniverse.io/terms-of-service" target='_blank'><u>Terms of Service</u></a> ({royalty}% Marketplace Royalty and {authorRoyalty}% Author Royalty). After the sell, you will receive an amount of {sellPrice? parseFloat(sellPrice) * (1 - (royalty + authorRoyalty) / 100) : 0} $USDC</p>
            </div>
            <button className='btn-main lead mb-5' onClick={addListing} disabled={sellPrice ? false : true}>Add for Sale</button>
          </div>
        </div>
      )}
      <Dialog
          open={showModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
      >
        <div className={classes.root}>
          <div className={classes.topBlock}>
            <CheckCircleOutlinedIcon className={classes.icon} />
          </div>  
          <div className={classes.bottomBlock}>
            <Typography className={classes.title}>
                {t("You need to connect your wallet first!")}
            </Typography>
            <div className={classes.buttonBlock}>
              <button
                className="btn-main"
                onClick={()=>setShowModal(false)}
              >
                {t("Close")} 
              </button>
              <button
                className="btn-main"
                onClick={() => { auth(); setShowModal(false); }}
              >
                {t("Connect")}
              </button>
            </div>
          </div>
        </div>
        { serverError && <ModalError open={serverError} onClose={() => {setServerError(false); setShowModal(false);}}/>}   
      </Dialog>
      <Dialog
          open={showAlertModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
      >
          <div className={classes.root}>
              <div className={classes.topBlock}>
                  <CheckCircleOutlinedIcon className={classes.icon} />
              </div>  
              <div className={classes.bottomBlock}>
                  <Typography className={classes.title}>
                      {alertMessage}
                  </Typography>
                  <div className={classes.alertButtonBlock}>
                      <button
                          className="btn-main"
                          onClick={()=>{setShowAlertModal(false)}}
                      >
                          {t("Close")} 
                      </button>
                  </div>
              </div>
          </div>
      </Dialog>
      <Dialog
          open={showTransferNft}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className='alert-modal'
      >
          <div className='checkout'>
            <div className='maincheckout'>
              <button
                className='btn-close'
                onClick={() => setShowTransferNft(false)}
              >
                x
              </button>
              <div className='heading'>
                <h3>{t("Manual Transfer")}</h3>
              </div>
              <p>
                <span>Please insert the wallet address you which to transfer this NFT to.</span>
                <span> Make sure this wallet address is compatible with AVAX C-CHAIN.</span>
                <span> Earniverse does not take any responsibility for any wrong transfer executed that will not be subject to refund.</span>
              </p>
              <span className='bold'>Enter wallet address.</span>
              {<FormControl className={classes.formControl}>
                <Autocomplete
                  id="free-solo-demo"
                  disablePortal
                  freeSolo
                  value={transferAddress}
                  getOptionLabel={(option)=>{
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (!option) {
                      return '';
                    }
                    return option.metamask;
                  }}
                  options={transferAddressList}
                  onInputChange={(event, newInputValue) => {
                    if(transferAddressList.find(obj => {return obj.metamask === newInputValue})) {
                      setSendClicked(false);
                      setTransferAddress(transferAddressList.find(obj => {return obj.metamask === newInputValue}));
                    }
                    setSearchAddress(newInputValue);
                  }}
                  onChange={(event, value, reason) => {
                    if(reason === "clear") {
                      setTransferAddress(0);
                      setTransferAddressList([]);
                    }
                    else {
                      setSendClicked(false);
                      setTransferAddress(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params}
                      margin="normal"
                      placeholder="Wallet Address"
                      className="form-control"
                      style={{width: '100%'}}
                      onChange={(e) => {
                        handleSearchAddress(e.target.value)
                      }}
                      value={searchAddress}
                    />
                  )}
                  style={{margin: 'auto', width: '100%'}}
                />
                {isSendClicked && transferAddress === 0 && <p style={{color: 'red'}}>Please select address.</p>}
                {transferAddress?.metamask && searchAddress && <>
                  <div className='heading'>
                    <p>Name: </p>
                    <div className='subtotal'>{transferAddress?.username}</div>
                  </div>
                </>}
              </FormControl>}
              <div className={classes.transferButtonBlock}>
                <button
                    className="btn-main"
                    style={{margin: 10}}
                    disabled={!transferAddress?.metamask || !searchAddress}
                    onClick={()=>{if(transferAddress===0) setSendClicked(true); else sendManualTransfer();}}
                >
                    {t("Send")} 
                </button>
              </div>
            </div>
          </div>
      </Dialog>
    </div>
  );
};

export default memo(AssetDetails);
